.whoBanner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1vw;
}

.lists {
    display: flex;
    flex-direction: row;
    justify-content: center;
    line-height: .5vw;
}

.listLeft {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.whoImg {
    height: 30vw;
    margin-top: 10vw;
}

.listRight {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.accent5 {
    position: absolute;
    top: 151vw;
    left: 65vw;
    z-index: -5;
}

.accent6 {
    position: absolute;
    top: 156vw;
    left: 50vw;
    z-index: -5;
}

@media only screen and (max-width: 1100px) {
    .accent5 {
        top: 166vw;
    }

    .accent6 {
        top: 179vw;
    }
}

@media only screen and (max-width: 600px) {

.whoBanner {
    flex-direction: column-reverse;
}
.whoImg {
    height: 50vw;
    margin-top: 10vw;
}
.accent5 {
    top: 388vw;
    left: 65vw;
}
.accent6 {
    top: 409vw;
    left: 20vw;
}
}