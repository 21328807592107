.aboutJane {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 15px 0;
}

.aboutImg {
    height: 30vw;
    margin: 15px 0;
}

.leftColJane {
    width: 45%;
}

.rightColJane {
    width: 45%;
}


@media only screen and (max-width: 600px) {
    .aboutJane {
        flex-direction: column;
    }

    .aboutImg {
        height: 75vw;
        align-self: center;
    }

    .rightColJane {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .leftColJane {
        width: 90%;
        align-self: center;
    }
}