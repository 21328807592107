

.carouselDiv {
    background-color: #ed8159;
    height: 45vw;
}

.carouselTextContainer {
    margin: auto;
    width: 50%;
    color: white;
    text-align: center;
}

.carouselText {
    text-align: center;
    font-weight: 100;
}

.carouselFrom {
    text-align: center;
}

.carousel {
    height: 34vw;
}

.carouselTitle {
    text-align: center;
    color: white;
    padding-top: 2vw;
    font-weight: bold;
    margin-top: 0;
}

.carouselSubTitle {
    text-align: center;
    color: white;
    padding-bottom: 2vw;
    font-size: 1.5vw;
}

.carousel-control-prev {
    padding-bottom: 15vw;
}

.carousel-control-next {
    padding-bottom: 15vw;
}

@media only screen and (max-width: 600px) {

.carouselDiv {
    height: 74vw;
}

.carouselText {
    margin: 0 15px 15px 15px;
}

.carouselTextContainer {
    margin: auto;
    width: 70%;
    color: white;
    text-align: center;
}

.carousel {
    height: 52vw;
}

.carouselTitle {
    text-align: center;
    color: white;
    padding-top: 4vw;
    font-weight: bold;
}

.carouselSubTitle {
    text-align: center;
    color: white;
    padding-bottom: 2vw;
    font-size: 2.5vw;
}

.carousel-control-prev {
    padding-bottom: 17vw;
}

.carousel-control-next {
    padding-bottom: 17vw;
}
}
@media only screen and (min-width: 1100px) {

.carouselDiv {
    height: 26vw;
}

.carouselTextContainer {
    width: 50%;
}

.carousel {
    height: 14vw;
}

.carouselTitle {
    padding-top: 2vw;
}

.carouselSubTitle {
    padding-bottom: 2vw;
    font-size: 1.25vw;
}

}