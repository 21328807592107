.App {
  text-align: center;
}

h2 {
  font-family: brandon-grotesque, sans-serif;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  font-size: 2.5vw;
  line-height: 2.5vw;
}

h3 {
  margin: 0;
    font-family: brandon-grotesque, sans-serif;
}

ul {
  text-align: left;
  font-size: 2.2vw;
  line-height: 3.5vw;
  font-family: brandon-grotesque, sans-serif;
  padding-left: 3%;
}

p {
  font-size: 2.2vw;
  font-family: brandon-grotesque, sans-serif;
  text-align: left;
}

.navy{
  color: #004a61;
}

.lilac {
  color: #e0c4de;
}

.orange{
  color: #ed8159;
}

.blue {
  color: #52adc7;
}

.mustart {
  color: #f1b564;
}

.leftCol {
  width: 45%;
}

.rightCol {
  width: 45%;
}

@media only screen and (max-width: 600px) {

.leftCol {
  width: 70%;
}

.rightCol {
  width: 70%;
}

h2 {
  font-size: 5.5vw;
  line-height: 5.5vw;
}

h3 {
  margin: 0;
    font-family: brandon-grotesque, sans-serif;
}

ul {
  text-align: left;
  font-size: 3.4vw;
  line-height: 4.5vw;
  padding-left: 5%;
}

p {
  font-size: 3.4vw;
  font-family: brandon-grotesque, sans-serif;
  text-align: left;
}
}

@media only screen and (min-width: 1100px) {

.leftCol {
  width: 45%;
}

.rightCol {
  width: 45%;
}

h2 {
  font-size: 2.5vw;
  line-height: 2.5vw;
}

h3 {
  margin: 0;
    font-family: brandon-grotesque, sans-serif;
}

ul {
  text-align: left;
  font-size: 1.25vw;
  line-height: 2.5vw;
  padding-left: 3%;
}

p {
  font-size: 1.25vw;
  font-family: brandon-grotesque, sans-serif;
  text-align: left;
}
}