.purchaseBanner {
    margin-top: 20vw;
    padding-top: 5vw;
    background-color: #52adc7;
    padding-bottom: 5vw;
}

.purchaseTitle {
    text-align: center;
    color: white;
}

.purchaseSubtitle {
    text-align: center;
}

.purchaseContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.smallLeftCol {
  padding-top: 20px;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #6abad0;
  margin-right: 1.5vw;
  border-radius: 5px;
  color: white;
}

.smallMiddleCol {
    padding-top: 20px;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #6abad0;
    border-radius: 5px;
    color: white;
  }

.smallRightCol {
  padding-top: 20px;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #6abad0;
  border-radius: 5px;
  margin-left: 1.5vw;
  color: white;
}

.priceText {
    font-size: 2.5vw;
    font-weight: bold;
    margin-top: .25vw;
    margin-bottom: .25vw;
    text-align: center;
}

.shippingText {
    font-size: 1.7vw;
    margin: .25vw 15px .25vw 15px;
    text-align: center;
}

.shippingList {
    font-size: 1.7vw;
    margin: .25vw 15px .25vw 15px;
    line-height: 2.3vw;
}

.shippingItem {
    margin-bottom: 10px;
}

.orderInfo {
    margin-top: 3vw;
    font-size: 2.5vw;
}

.boxIcon {
    margin-top: 2vw;
    margin-bottom: 1vw;
    height: 7vw;
    width: 5vw;
}

.boxesIcon {
    margin-top: 2vw;
    margin-bottom: 1vw;
    height: 7vw;
    width: 7vw;
}

@media only screen and (max-width: 600px) {
.purchaseTitle {
    padding-left: 6vw;
    padding-right: 6vw;
}

.purchaseContainer {
    flex-direction: column;
    align-items: center;
}

.smallLeftCol {
  width: 75%;
  height: 53vw;
  margin: 0px 0px 22px 0px;
}

.smallMiddleCol {
    width: 75%;
    margin: 0px 0px 22px 0px;
}

.smallRightCol {
  width: 75%;
  margin: 0px;

}

.priceText {
    font-size: 5vw;
}

.shippingText {
    font-size: 2.5vw;
}

.shippingList {
    font-size: 2.5vw;
    line-height: 3.2vw;
}

.orderInfo {
    margin-top: 5vw;
    font-size: 3.5vw;
}

.boxIcon {
    margin-bottom: 1vw;
    width: 10vw;
    height: 10vw;
    margin-top: 4vw;
}

.boxesIcon {
    margin-top: 4vw;
    margin-bottom: 1vw;
    width: 10vw;
    height: 10vw;
}
}
@media only screen and (min-width: 1100px) {

.boxIcon {
    width: 5vw;
    height: 4vw;
}

.boxesIcon {
    width: 7vw;
    height: 4vw;
}
.priceText {
    font-size: 2vw;
}

.shippingText {
    font-size: 1vw;
}

.shippingList {
    font-size: 1vw;
    line-height: 23px;
}

.smallLeftCol {
  width: 23%;
}
.smallMiddleCol {
    width: 23%;
}
.smallRightCol {
  width: 23%;
}
.orderInfo {
    font-size: 2vw;
}
}