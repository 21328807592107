.heartBorder {
    background-color: #ffffff;
    height: 30vw;
    position: relative;
    text-align: center;
    background-image: url(../media/Hero-1.png);
    background-position: center;
    background-size: cover;
}

.introTitle {
    font-size: 1.25vw;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 400;
    font-style: normal;
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    letter-spacing: .25vw;
    color: #ffffff;
}

.brand {
    letter-spacing: .25vw;
    background-color: #f1b564;
    font-size: 2vw;
    margin: 0;
    height: 3.2vw;
    padding: 1vw;
    color: white;
}

@media only screen and (max-width: 600px) {

.brand {
    font-size: 3.5vw;
    height: 5.25vw;
}

.borderSvg {
    height: 70vw;
    width: 90vw;
}

.heartBorder {
    height: 34vw;
    margin-bottom: 3vw;
}

.introTitle {
    font-size: 2.5vw;
    width: 80vw;
}


  }