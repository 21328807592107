.footerContainer {
    background-color: #f1b564;
    height: 18vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footerBrand {
    color: white;
    font-size: 2vw;
    letter-spacing: .25vw;
}

.footerText {
    font-size: 1.7vw;
    line-height: .5vw;
    font-family: brandon-grotesque, sans-serif;
}

.ocklloContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ocklloLogo {
    height: 3vw;
    padding-bottom: 1vw;
}

.ocklloText {
    margin: 0;
    font-family: brandon-grotesque, sans-serif;
    font-size: 1.2vw;
    color: black;
    padding-top: .88vw;
    padding-right: .2vw;
}

@media only screen and (max-width: 600px) {

.footerContainer {
    height: 26vw;
}

.heartLine {
    padding-top: 3.5vw;
    padding-bottom: 0.5vw;
    width: 29vw;
    height: 8vw;
}

.footerBrand {
    font-size: 3vw;
    letter-spacing: .5vw;
}

.footerText {
    font-size: 2vw;
}
}

@media only screen and (min-width: 1100px) {

.footerText {
    font-size: 1vw;
}
.heartLine {
    width: 16vw;
    height: 2vw;
    padding-top: 2.5vw;
    padding-bottom: .5vw;
}

.footerContainer {
    height: 13vw;
}
.ocklloLogo {
    height: 2vw;
    padding-bottom: 0vw;
}

.ocklloText {
    margin: 0;
    font-family: brandon-grotesque, sans-serif;
    font-size: .8vw;
    color: black;
    padding-top: .6vw;
    padding-right: .2vw;
}
}