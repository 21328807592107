.placesBanner {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 23vw;
    background-color: #ed8159;
    color: white;
}

.placesContainer {
    display: flex;
    flex-direction: row;
}

.places {
    width: 17vw;
    line-height: 1.2vw;
    margin-right: 15px;
    margin-left: 15px;
}

.bold{
    font-weight: bold;
}

.placesTitle {
    margin-top: 1.5vw;
}
.placeImg {
    height: 30vw;
}

@media only screen and (max-width: 1100px) {
    .places {
        line-height: 2.2vw;
    }

    .placesBanner {
        height: 28vw;
    }
}


@media only screen and (max-width: 600px) {

.placesBanner {
    height: 98vw;
}

.places {
    width: 67vw;
    line-height: 4.2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.placesContainer {
    flex-direction: column;
}

.placesText {
    margin: 0px;
}
}