.useBanner {
    margin-top: 6vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 50vw;
}

.useImg {
    height: 30vw;
}

@media only screen and (max-width: 1100px) {
    .useImg {
        height: 38vw;
    }
}

@media only screen and (max-width: 600px) {

.useBanner {
    margin-top: 12vw;
    flex-direction: column;
    height: 135vw;
    align-items: center;
}

.useImg {
    height: 45vw;
    align-self: center;
}
}